import React from "react";
import {Link} from "gatsby";
import Layout from "../components/layout"
import {getTranslatedFields, getCurrentLocale, getTranslatedValue} from "../helpers/fields";
const config = require('../../config');
const {getRoomInThemeSlug} = require("../helpers/slugs");

class Theme extends React.Component {
    render() {
        const translatedFields = getTranslatedFields(this);
        const thisTheme = this.props.pageContext.data;

        const {title, featuredimage, description} = translatedFields;

        const relatedRooms = this.props.pageContext.relatedRooms;
        const locale = getCurrentLocale(this);

        return (
            <Layout lang={getCurrentLocale(this)} className='kunstwerken' title={title} page={this}>
                <div className="wrapper">

                    {relatedRooms.map((room, index) => {
                        return (
                            <article key={index}>
                                <Link to={getRoomInThemeSlug(thisTheme, room, locale)}>
                                    <figure>
                                        <img src={`${config.siteurl}${room.fieldValues.featuredimage.thumbnail}`}
                                             alt={room.fieldValues.featuredimage.alt}/>
                                    </figure>
                                    <div className="text">
                                        <h3>{getTranslatedValue(this, room.fieldValues.title)}</h3>
                                        <p className='room-name'>{getTranslatedValue(this, room.fieldValues.name)}</p>
                                    </div>
                                    <span className="cta"></span>
                                </Link>
                            </article>
                        )
                    })}
                </div>
            </Layout>
        );
    }
}

export default Theme;
